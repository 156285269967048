import React, { useEffect, useState } from 'react'
import _, { map } from 'underscore';
import './Home.css'
// import { font } from './Gilroy-Regular-normal';
// import {html2canvas, jsPDF} from 'app/ext';

import { jsPDF } from "jspdf";

import './Gilroy-Regular-normal'
import './LibreFranklin-Medium-normal'

import BidOfferEntry from '../../components/BidOfferEntry/'
import axios from 'axios';

export default function Home(props) {

    const [stacks, setStacks] = useState([])
    const [chartData, setChartData] = useState([])
    const [lastUpdate, setLastUpdate] = useState([])
    const [username, setUsername] = useState('')

    const [csvData, setCsvData] = useState([])
    const [exportFileName, setExportFileName] = useState('');



    useEffect(() => {



        if(localStorage.getItem('token') == null) {
            document.location.href = '/login'
        }

        let lcUser = localStorage.getItem('email');
        setUsername(lcUser);

        axios.post(`${process.env.REACT_APP_API_ROOT}/assesmentData`, {}, {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {

            if (res.data) {

                let prices = res.data;


                let tmpStacks = {
                    group1: {},
                    group2: {},
                    group3: {},
                    group4: {},
                    group5: {},
                    group6: {}, 
                    group7: {},
                };

                let thgPrices = prices.filter(price => price.inputCategory === 1);
                let hbePrices = prices.filter(price => price.inputCategory === 2);
                let rtfcPrices = prices.filter(price => price.inputCategory === 3);
                let vtgPrices = prices.filter(price => price.inputCategory === 4);
                let biomPrices = prices.filter(price => price.inputCategory === 5);
                let hvoPrices = prices.filter(price => price.inputCategory === 6);
                let hefaPrices = prices.filter(price => price.inputCategory === 7);

                let latestUpdateTime = new Date(Math.max(...prices.map(e => new Date(e.date ?? 0))));
                setLastUpdate(latestUpdateTime.toLocaleDateString());


                tmpStacks.group1.category = 'THG-Quote (€/mt)';
                tmpStacks.group1.products = thgPrices;

                tmpStacks.group2.category = 'HBE (€/GJ)';
                tmpStacks.group2.products = hbePrices;

                tmpStacks.group3.category = 'RTFC (p/RTFC)';
                tmpStacks.group3.products = rtfcPrices;

                tmpStacks.group4.category = 'VertiCer (€/MWh HHV)';
                tmpStacks.group4.products = vtgPrices;

                tmpStacks.group5.category = 'German biomethane (€/MWh HHV)';
                tmpStacks.group5.products = biomPrices;

                tmpStacks.group6.category = 'HVO ($/m3)';
                tmpStacks.group6.products = hvoPrices;

                tmpStacks.group7.category = 'SPK ($/m3)';
                tmpStacks.group7.products = hefaPrices;

                setStacks(tmpStacks)


            }
        })



        axios.post(`${process.env.REACT_APP_API_ROOT}/assesmentHistoryData`, {}, {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {



            let chart1SourceData = res.data.filter(pid => (pid.shortName.indexOf('UER 23') > -1 || pid.shortName.indexOf('UER 24') > -1 || pid.shortName.indexOf('THG 23 O') > -1 || pid.shortName.indexOf('THG 24 O') > -1) && pid.Product.inputCategory == 1)
            let chart2SourceData = res.data.filter(pid => (pid.shortName.indexOf('HBE') > -1 && pid.shortName.indexOf('A') > -1 && (pid.shortName.indexOf('23') > -1 || pid.shortName.indexOf('24') > -1)) && pid.Product.inputCategory == 2)
            let chart3SourceData = res.data.filter(pid => (pid.shortName.indexOf('RTFC') > -1 && (pid.shortName.indexOf('23') > -1 || pid.shortName.indexOf('24') > -1)) && pid.Product.inputCategory == 3)


            var groupedByDates = _.groupBy(chart1SourceData, function (obj) {
                return obj.date;
            });

            var groupedByDates2 = _.groupBy(chart2SourceData, function (obj) {
                return obj.date;
            });

            var groupedByDates3 = _.groupBy(chart3SourceData, function (obj) {
                return obj.date;
            });



            // UER 21 (#9DC3E6), THG 21 O/HBE 21 O/RTFC 21 (#4472C4), UER 22 (#A9D18E), THG 22 O/HBE 22 O/RTFC 22 (#70AD47), THG 23 O (#BF9000)




            // TODO - Get and format this data from the PRoductMeta table in DB. Data already there
            let chart1products = [
                { shortName: 'UER 23', color: '#A9D18E', productId: '203b26b9-bf82-4b41-b20f-10ff2af86251'},
                { shortName: 'UER 24', color: '#9DC3E6' , productId: '6a79e81a-add9-4e49-80e7-606d1fedad75'},
                { shortName: 'THG 23 O', color: '#BF9000' , productId: '48f65c99-0f49-4216-7de6-d8c8f6421b1c'},
                { shortName: 'THG 24 O', color: '#70AD47' , productId: 'fb96d605-0106-4fff-9fe3-9ef6b6c781d0'},
            ]

            let chart2products = [
                // color: '#4472C4'
                { shortName: 'HBE 23 A', color: '#70AD47' , productId: '258f717e-d26b-42c5-9b8d-8de45c3ec24e'},
                { shortName: 'HBE 24 A', color: '#A9D18E' , productId: '3eeac2e7-0625-40c7-889d-5ba04d51e4c8'}
            ]

            let chart3products = [
                // color: '#4472C4'
                { shortName: 'RTFC 23 NC', color: '#70AD47' , productId: 'd7f30604-358f-43b5-aa32-9ad54a8ba9b8'}, 
                { shortName: 'RTFC 24 NC', color: '#9DC3E6' , productId: '8806f530-2253-47f0-97a1-8c3de12da4a5'}
            ]



            let graph1DataFixed = []

            for (const property in groupedByDates) {

                let thisDate = { name: property.split('T')[0] }

                groupedByDates[property].forEach(midP => {
                    thisDate[midP.productId] = midP.mid == 0 ? midP.price > 0 ? midP.price : null : midP.mid;
                })

                graph1DataFixed.push(thisDate);
            }

            let graph2DataFixed = []

            for (const property in groupedByDates2) {

                let thisDate = { name: property.split('T')[0] }

                groupedByDates2[property].forEach(midP => {
                    thisDate[midP.productId] = midP.mid == 0 ? midP.price > 0 ? midP.price : null : midP.mid;
                })

                graph2DataFixed.push(thisDate);
            }

            let graph3DataFixed = []

            for (const property in groupedByDates3) {

                let thisDate = { name: property.split('T')[0] }

                groupedByDates3[property].forEach(midP => {
                    thisDate[midP.productId] = midP.mid == 0 ? midP.price > 0 ? midP.price : null : midP.mid;
                })

                graph3DataFixed.push(thisDate);
            }


            graph1DataFixed = graph1DataFixed.sort((a,b) =>  new Date(a.name)- new Date(b.name));
            graph2DataFixed = graph2DataFixed.sort((a,b) =>  new Date(a.name)- new Date(b.name));
            graph3DataFixed = graph3DataFixed.sort((a,b) =>  new Date(a.name)- new Date(b.name));

            setChartData({
                chart1products: chart1products,
                chart1: graph1DataFixed,
                chart2: graph2DataFixed,
                chart2products: chart2products,
                chart3: graph3DataFixed,
                chart3products: chart3products,
            })

            let csv = [];
            res.data.forEach(item => {
                csv.push(
                    {
                        product: item.shortName,
                        date: item.date.toString().substring(0,10),
                        bid: item.bid,
                        ask: item.ask,
                        assesment: item.price
                    }
                )
            })

            let dlTime = new Date();
            setExportFileName(`${dlTime.getFullYear().toString() + '_' + (dlTime.getMonth()+1).toString() + '_' + (dlTime.getDate()).toString()}_OLYX_Market_Update_History.csv`);
            setCsvData(csv);

        }).catch(e => {
            console.log('Error fetching results')
            console.log(e)

            if(e.toString().indexOf('403') > -1) {
                localStorage.setItem('token',null);
                document.location.href=('/')
            }

        });




    }, [])


    const printDocument = async () => {

        const input = document.getElementById('divToPrint');
        const divHeight = input.clientHeight
        const divWidth = input.clientWidth
        const ratio = divHeight / divWidth;

        html2canvas(input, { scale: 3}).then((canvas) => { // eslint-disable-line
          const imgData = canvas.toDataURL('image/jpeg');
          const pdfDOC = new jsPDF("p", "mm", "a4");  // eslint-disable-line
          pdfDOC.addFont('Gilroy-Regular-normal.ttf', 'Gilroy-Regular', 'normal');
            pdfDOC.addFont('LibreFranklin-Medium-normal.ttf', 'LibreFranklin-Medium', 'normal');

          const width = pdfDOC.internal.pageSize.width;
          let height = pdfDOC.internal.pageSize.height;
          height = ratio * width;

          pdfDOC.addImage(imgData, 'JPEG', 0, 0, width, height);
          let dlTime = new Date();


          pdfDOC.setFontSize(10);

        //   pdfDOC.addFileToVFS("Gilroy-Regular.ttf", GilroyRegular);
        //   pdfDOC.addFont("Gilroy-Regular.ttf", "Gilroy-Regular", "normal");

          pdfDOC.setFont("Gilroy-Regular");


          pdfDOC.textWithLink('Consult your                                           for trading opportunities. ', 59, 250, {url: 'https://www.olyx.nl/team/'});
          pdfDOC.setTextColor(0,0,255);

         // doc.line(x-from,y-from,x-to,y-to);


          pdfDOC.textWithLink('personal OLYX broker', 80, 250, {url: 'https://www.olyx.nl/team/'});



          pdfDOC.save(`${dlTime.getFullYear().toString() + '_' + (dlTime.getMonth()+1).toString() + '_' + (dlTime.getDate()).toString()}_Daily_OLYX_Market_Update.pdf`);
        })

      }




      const logout = () => {
          localStorage.removeItem('token');
          document.location.href='/login'
      }

    return (
        <>
            <svg style={{ display: 'none' }}>

                <symbol id="down" viewBox="0 0 16 16">
                    <polygon points="3.81 4.38 8 8.57 12.19 4.38 13.71 5.91 8 11.62 2.29 5.91 3.81 4.38" />
                </symbol>
                <symbol id="users" viewBox="0 0 16 16">
                    <path d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,15a7,7,0,0,1-5.19-2.32,2.71,2.71,0,0,1,1.7-1,13.11,13.11,0,0,0,1.29-.28,2.32,2.32,0,0,0,.94-.34,1.17,1.17,0,0,0-.27-.7h0A3.61,3.61,0,0,1,5.15,7.49,3.18,3.18,0,0,1,8,4.07a3.18,3.18,0,0,1,2.86,3.42,3.6,3.6,0,0,1-1.32,2.88h0a1.13,1.13,0,0,0-.27.69,2.68,2.68,0,0,0,.93.31,10.81,10.81,0,0,0,1.28.23,2.63,2.63,0,0,1,1.78,1A7,7,0,0,1,8,15Z" />
                </symbol>
                <symbol id="collection" viewBox="0 0 16 16">
                    <rect width="7" height="7" />
                    <rect y="9" width="7" height="7" />
                    <rect x="9" width="7" height="7" />
                    <rect x="9" y="9" width="7" height="7" />
                </symbol>

                <symbol id="settings" viewBox="0 0 16 16">
                    <rect x="9.78" y="5.34" width="1" height="7.97" />
                    <polygon points="7.79 6.07 10.28 1.75 12.77 6.07 7.79 6.07" />
                    <rect x="4.16" y="1.75" width="1" height="7.97" />
                    <polygon points="7.15 8.99 4.66 13.31 2.16 8.99 7.15 8.99" />
                    <rect x="1.28" width="1" height="4.97" />
                    <polygon points="3.28 4.53 1.78 7.13 0.28 4.53 3.28 4.53" />
                    <rect x="12.84" y="11.03" width="1" height="4.97" />
                    <polygon points="11.85 11.47 13.34 8.88 14.84 11.47 11.85 11.47" />
                </symbol>
                <symbol id="options" viewBox="0 0 16 16">
                    <path d="M8,11a3,3,0,1,1,3-3A3,3,0,0,1,8,11ZM8,6a2,2,0,1,0,2,2A2,2,0,0,0,8,6Z" />
                    <path d="M8.5,16h-1A1.5,1.5,0,0,1,6,14.5v-.85a5.91,5.91,0,0,1-.58-.24l-.6.6A1.54,1.54,0,0,1,2.7,14L2,13.3a1.5,1.5,0,0,1,0-2.12l.6-.6A5.91,5.91,0,0,1,2.35,10H1.5A1.5,1.5,0,0,1,0,8.5v-1A1.5,1.5,0,0,1,1.5,6h.85a5.91,5.91,0,0,1,.24-.58L2,4.82A1.5,1.5,0,0,1,2,2.7L2.7,2A1.54,1.54,0,0,1,4.82,2l.6.6A5.91,5.91,0,0,1,6,2.35V1.5A1.5,1.5,0,0,1,7.5,0h1A1.5,1.5,0,0,1,10,1.5v.85a5.91,5.91,0,0,1,.58.24l.6-.6A1.54,1.54,0,0,1,13.3,2L14,2.7a1.5,1.5,0,0,1,0,2.12l-.6.6a5.91,5.91,0,0,1,.24.58h.85A1.5,1.5,0,0,1,16,7.5v1A1.5,1.5,0,0,1,14.5,10h-.85a5.91,5.91,0,0,1-.24.58l.6.6a1.5,1.5,0,0,1,0,2.12L13.3,14a1.54,1.54,0,0,1-2.12,0l-.6-.6a5.91,5.91,0,0,1-.58.24v.85A1.5,1.5,0,0,1,8.5,16ZM5.23,12.18l.33.18a4.94,4.94,0,0,0,1.07.44l.36.1V14.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V12.91l.36-.1a4.94,4.94,0,0,0,1.07-.44l.33-.18,1.12,1.12a.51.51,0,0,0,.71,0l.71-.71a.5.5,0,0,0,0-.71l-1.12-1.12.18-.33a4.94,4.94,0,0,0,.44-1.07l.1-.36H14.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H12.91l-.1-.36a4.94,4.94,0,0,0-.44-1.07l-.18-.33L13.3,4.11a.5.5,0,0,0,0-.71L12.6,2.7a.51.51,0,0,0-.71,0L10.77,3.82l-.33-.18a4.94,4.94,0,0,0-1.07-.44L9,3.09V1.5A.5.5,0,0,0,8.5,1h-1a.5.5,0,0,0-.5.5V3.09l-.36.1a4.94,4.94,0,0,0-1.07.44l-.33.18L4.11,2.7a.51.51,0,0,0-.71,0L2.7,3.4a.5.5,0,0,0,0,.71L3.82,5.23l-.18.33a4.94,4.94,0,0,0-.44,1.07L3.09,7H1.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H3.09l.1.36a4.94,4.94,0,0,0,.44,1.07l.18.33L2.7,11.89a.5.5,0,0,0,0,.71l.71.71a.51.51,0,0,0,.71,0Z" />
                </symbol>
                <symbol id="collapse" viewBox="0 0 16 16">
                    <polygon points="11.62 3.81 7.43 8 11.62 12.19 10.09 13.71 4.38 8 10.09 2.29 11.62 3.81" />
                </symbol>
            </svg>
            <header class="page-header">
                <nav>
                    <a href="#0" aria-label="forecastr logo" class="logo">
                        <img src='/logonew.png' style={{ width: 125, marginLeft: -10 }} />
                    </a>
                    <button class="toggle-mob-menu" aria-expanded="false" aria-label="open menu">
                        <svg width="20" height="20" aria-hidden="true">
                            <use href="#down"></use>
                        </svg>
                    </button>
                    <ul class="admin-menu">
                        <li class="menu-heading">
                            <h3>Admin</h3>
                        </li>

                        <li>
                            <a href="#0">
                                <svg>
                                    <use href="#collection"></use>
                                </svg>
                                <span>Dashboard</span>
                            </a>
                        </li>

                        {/* <li class="menu-heading">
                            <h3>Settings</h3>
                        </li> */}
                        <li>
                            <a onClick={logout}>
                                <svg>
                                    <use href="#settings"></use>
                                </svg>
                                <span>LogOut</span>
                            </a>
                        </li>

                    </ul>
                </nav>
            </header>
            <section class="page-content">
                <section class="search-and-user">
                    <form>
                        {/* <input type="search" placeholder="Search Pages...">
      <button type="submit" aria-label="submit form">
        <svg aria-hidden="true">
          <use xlink:href="#search"></use>
        </svg>
      </button> */}
                    </form>
                    <div class="admin-profile">




                    <form action={`${process.env.REACT_APP_API_ROOT}/assesmentHistoryDataExcel?authtoken=${localStorage.getItem('token')}`} method="post">
                        <button type="submit" style={{border: '1px solid', fontWeight: 'bold', padding: 5, marginLeft: 10, width: 150}}>Export Excel Data</button>
                    </form>


                    <button onClick={printDocument} style={{border: '1px solid', fontWeight: 'bold', padding: 5, marginLeft: 10}}>Download PDF</button>

                        <span class="greeting">{username}</span>
                        <div class="notifications">
                            {/* <span class="badge">1</span> */}
                            <svg>
                                <use href="#users"></use>
                            </svg>
                        </div>
                    </div>
                </section>
                <section >

                <div id="divToPrint" style={{width: 'fit-content', height: 'fit-content'}}>
                    <BidOfferEntry stacks={stacks} lastUpdate={lastUpdate} chartData={chartData}  />
                    </div>
                    <div style={{margin: 'auto',textAlign:'center', marginTop: 20, fontFamily: 'Gilroy Medium'}}>
                        <span>Consult your <a style={{textDecoration: 'underline', color: 'blue'}} href='https://www.olyx.nl/team/'>personal OLYX broker</a> for trading opportunities.</span>

                    </div>

                </section>
                <footer class="page-footer">

                </footer>
            </section>
        </>
    )
}
